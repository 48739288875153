/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */

/* Plugins */
import React from 'react';
import { graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* Components */
import {
  SEO,
  Container,
  Heading,
  Section,
  Article,
  CTAButton,
  ProductGallery,
} from '../../components/components';

/* Styles */
import { ArticleCTAWrapper } from '../../components/UI/Button/CTAButton/CTAButton.styled';

/* Messages */
import { mainHeader, catalogInfo, graphicDesign } from '../../messages/katalogi/katalogi.messages';

const Katalogi = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => ({
    ...node.childImageSharp,
    thumbAlt: node.name,
  }));

  return (
    <>
      <SEO title={`${mainHeader} reklamowe`} />
      <Container subPage>
        <Heading headingLevel="h1" title={`${mainHeader} reklamowe`} centered highlightedLastWord />
        <Section
          iconSrc={data.file.childImageSharp.gatsbyImageData}
          iconAlt={mainHeader}
          smallerIcon
        >
          <Article>
            {catalogInfo.content.map(({ article, cta, gallery }, index) => (
              <React.Fragment key={index}>
                <Markdown rehypePlugins={[rehypeRaw]} children={article} />
                {cta && (
                  <ArticleCTAWrapper>
                    <CTAButton title="Wyślij zapytanie" />
                  </ArticleCTAWrapper>
                )}
                {gallery && <ProductGallery title="Realizacje" images={images} />}
              </React.Fragment>
            ))}
            <AnchorLink
              to="/drukarnia#introligatornia-i-uszlachetnienia"
              title="Introligatornia i uszlachetnienia"
              className="anchor-link"
            />
          </Article>
        </Section>

        <Section>
          <Article>
            <Markdown rehypePlugins={[rehypeRaw]} children={graphicDesign} />
            <AnchorLink
              to="/projekty-graficzne#cennik"
              title="Cennik projektów graficznych"
              className="anchor-link"
            />
          </Article>
        </Section>
      </Container>
    </>
  );
};

export const query = graphql`
  query {
    file(relativeDirectory: { eq: "icons/katalogi" }) {
      name
      childImageSharp {
        gatsbyImageData
      }
    }
    allFile(filter: {relativeDirectory: {eq: "gallery/katalogi"}}, sort: { fields: name }) {
      edges {
        node {
          name
          childImageSharp {
            thumb: gatsbyImageData(placeholder: BLURRED)
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default Katalogi;
