import EcoFriendyLogo from '../../assets/images/icons/eco-friendly-logo.webp';

export const mainHeader = 'Katalogi';

export const catalogInfo = {
  icon: true,
  priceList: true,
  content: [
    {
      article: `## <span class="orange">Druk katalogów online</span>
      \n Jest coś wyjątkowego w przeglądaniu wydrukowanego katalogu produktowego lub reklamowego. To pierwszy krok na drodzę kupującego.,
      \n Zaprezentuj swojemu Klientowi bogato uszlachetniony i zdobiony katalog, z którym będzie chciał się
      zapoznać. Niech będzie to wyjątkowa prezentacja Twoich produktów i oferty.
      `,
    },
    {
      article: `## <span class="orange">Katalog reklamowy cena</span>
      \n Cena druku katalogów uzależniona jest od jego specyfikacji, abyśmy mogli przygotować ofertę
      potrzebujemy następujących informacji:
      \n * rodzaj katalogu tj. szyty, klejony czy spiralowany,
      \n * format,
      \n * papier okładki i środka,
      \n * liczba stron,
      \n * dodatkowe uszlachetnienia (opis dostępnych uszlachetnień poniżej),
      \n * nakład.
      `,
    },
    {
      cta: true,
    },
    {
      article: `## <span class="orange">Druk katalogów tanio</span>
      \n W oparciu o przesłane informacje postaramy się zaproponować jak najbardziej atrakcyjną cenę oraz
      doradzimy w kwestiach technicznych odnośnie oprawy, gramatury i rodzajów papierów oraz w wyborze
      optymalnych uszlachetnień.
      `,
    },
    {
      gallery: true,
    },
    {
      article: `## <span class="orange">Katalog szyty czy klejony</span>
      \n Katalog szyty to idealny wybór jeśli jego objętość ma nie więcej jak 60 stron. Możesz wybrać pomiędzy
      tradycyjnym szycie płaskim po dłuższym lub krótszym boku albo zdecydować się na zszywki oczkowe
      pozwalające na łatwe umieszczenie katalogu np. w segregatorze biurowym.
      \n Katalog klejony zalecany jest gdy objętość Twojego katalogu przekracza 60 stron. Trwałe klejenie klejem
      PUR sprawi, że kartki nie będą wypadać nawet pomimo niezbyt łagodnemu potraktowaniu, a grzbiet
      katalogu na długo będzie solidny i elastyczny.
      `,
    },
    {
      article: `## <span class="orange">Katalog spiralowany</span>
      \n Ten rodzaj oprawy sprawi, że Twój katalog nawet gdy jest bardzo obszerny będzie prezentował się
      bardziej subtelnie. Polecany szczególnie podczas częstej prezentacji treści np. podczas targów.
      Wygodne otwieranie jest sporym ułatwieniem, a ładnie dobrana spirala nadaje katalogi wyjątkowy wygląd.
      `,
    },
    {
      article: `## <span class="orange">Wybór papieru</span>
      \n Katalogi drukowane są na powlekanym papierze kredowym matowym i błyszczącym, niepowlekany
      offsetowym, ekologicznym niepowlekanym wyprodukowanym w 100% z makulatury i z certyfikatem
      FSC oraz na innych wybranych papierach.
      `,
    },
    {
      article: `### <span class="green">Postaw na EKO!</span> 
      \n Wydrukuj swój katalog na certyfikowanym ekologicznym papierze w 100% wyprodukowanym z
      makulatury i pokaż swoim Klientom że stawiasz na EKO!
      \n W naszej ofercie znajdziesz paletę papierów niepowlekanych Nautilus Classic, SuperWhite i Coocon
      offset oraz powlekanych Coocon Silk ponadto druk na wyżej wymienionych papierach odbywa się z
      wykorzystaniem farb biodegradowalnych co pozwala na oznakowanie wykonanych wydruków
      certyfikatem FSC.
      \n Wybierając EKO papier, pokaż że Twój katalog jest tylko nadzwyczajny, ale także “zielony” i dodaj jedną z
      poniższych informacji oraz dodatkowo EKO logo.
      \n <div class="eco-options-wrapper">
        <div class="eco-options">
        \n "wydrukowane na ekologicznym papierze recyklingowym"  \n "drukowane przy użyciu farb biodegradowalnych"  \n "druk na papierze wyprodukowanym w 100% z makulatury"
        </div>
        <img src=${EcoFriendyLogo} width="100" height="100" alt="eco-friendly-logo"/>
      </div>
      `,
    },
    {
      article: `## <span class="orange">Możliwe uszlachetnienia</span>
      \n Zapoznaj się z możliwymi uszlachetnieniami.
      `,
    },
  ],
};

export const graphicDesign = `## <span class="orange">Projekt graficzny</span> 
  \n Jeśli nie posiadasz jeszcze złożonego projektu folderu swojej firmy możesz na nas liczyć!
  \n Zajmujemy się składem wielostronnicowych broszur, opracowaniem layout'u, tworzeniem ilustracji i dostosowaniem zdjęć produktów oraz projektem okładki.
  \n Zapoznaj się z naszym cennikiem projektów graficznych
`;
